var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('headerv-2'),[_c('div',{staticClass:"register-login-section spad"},[_c('div',{staticClass:"container mx-auto"},[_c('div',{staticClass:"flex items-center justify-center h-fit"},[_c('div',{staticClass:"w-1/2"},[_c('div',{staticClass:"login-form"},[_c('h2',{staticClass:"text-lg"},[_vm._v("Reset Your Password")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{staticClass:"group-input"},[_c('label',{attrs:{"for":"username"}},[_vm._v("New Password *")]),_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'password',
                    {
                      rules: [
                        {
                          required: true,
                          message: 'Please input your password!',
                        },
                        {
                          min: 8,
                          message: 'Password must be 8 characters or more',
                        } ],
                    } ]),expression:"[\n                    'password',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Please input your password!',\n                        },\n                        {\n                          min: 8,\n                          message: 'Password must be 8 characters or more',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"autocomplete":"off"}})],1),_c('button',{class:[_vm.loading ? 'site-btn-active disabled' : 'site-btn', 'login-btn'],attrs:{"type":"submit"}},[_vm._v(" Reset Password "),(_vm.loading)?_c('a-spin'):_vm._e()],1)],1),_c('div',{staticClass:"switch-login"},[_c('router-link',{staticClass:"or-login",attrs:{"to":"sign-in"}},[_vm._v("Back to Login")])],1)],1)])])])])],_c('Footerv2')],2)}
var staticRenderFns = []

export { render, staticRenderFns }